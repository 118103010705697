import {
  CreateOrganizationRequest,
  OrganizationControllerApi as Ctrl
} from '@rallycry/api-suite-typescript/dist/apis/OrganizationControllerApi'
import { OrganizationProfileControllerApi } from '@rallycry/api-suite-typescript/dist/apis/OrganizationProfileControllerApi'
import {
  OrganizationMetaResource,
  OrganizationResource
} from '@rallycry/api-suite-typescript/dist/models'
import { OrganizationProfileCommand } from '@rallycry/api-suite-typescript/dist/models/OrganizationProfileCommand'
import { OrganizationProfileResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationProfileResource'
import { OrganizationUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/OrganizationUpdateCommand'
import { useCallback, useMemo } from 'react'
import { useConfig } from '../site/useConfig'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useOrganizationMeta } from './useOrganizationMeta'
import { useController } from '@/core/hooks/useSWRApi'
import { expander } from '@/core/expand'
import { useUserMeta } from '../user/useUserMeta'

export const useOrganization = (
  options?: EntityOptions & {
    organization?: OrganizationResource
    organizationMeta?: OrganizationMetaResource
  }
) => {
  const config = useConfig()
  const { read: meta } = useOrganizationMeta()
  const { read: userMeta } = useUserMeta()
  const { ctrl: profileCtrl } = useController(OrganizationProfileControllerApi)
  const { ctrl } = useController(Ctrl)

  const orgKey = config.orgKey!
  const metaRoot = meta.data?.root
  const expand = '_links,profile,settings'

  const entity = useReadEntity({
    key: 'useOrganization',
    idOrKey: orgKey,
    expand,
    ...options,
    metas: [userMeta, meta],
    read: (req, { root, access }) =>
      ctrl({
        metas: { root, access },
        expectedStatuses: [401],
        fallbackResponse: { key: orgKey }
      }).readOrganization(req),
    create: (req: CreateOrganizationRequest) => ctrl().createOrganization(req),
    update: (id, cmd: OrganizationUpdateCommand) =>
      ctrl().updateOrganization({ id, organizationUpdateCommand: cmd })
  })

  const refresh = async () =>
    entity.read.mutate(
      await ctrl({
        skipCache: true,
        metas: metaRoot ? { root: metaRoot } : undefined,
        expectedStatuses: [401],
        fallbackResponse: { key: orgKey }
      }).readOrganization({ idOrKey: orgKey, expand }),
      false
    )

  const updateProfile = async (id: number, cmd: OrganizationProfileCommand) => {
    await profileCtrl().updateOrganizationProfile({
      id,
      organizationProfileCommand: cmd,
      expand: ''
    })
    await meta.mutate()
  }

  const profile = expander<OrganizationProfileResource>(
    entity.read.data,
    'organizationProfile'
  )

  const organization = entity.read.data
  const activeOrgKey = organization?.key
  const storageUrl = profile?.storageUrl || 'https://cdn.rallycryapp.com'

  const route = config.rootMode ? '/' : `/${organization?.key}`
  const getRoutedPath = (path: string) => {
    // add trailing slash when missing to ensure routing works on root or normal modes
    const rerouted = route.endsWith('/') ? route : route + '/'
    // remove leading slash on path to allow user to represent paths with or without it
    const repath = path.startsWith('/') ? path.substring(1) : path
    return rerouted + repath
  }

  return {
    ...entity,
    meta,
    organization,
    profile,
    updateProfile,
    refresh,
    rootMode: config.rootMode,
    orgKey: organization?.key,
    route,
    redirect: config.rootMode ? '' : `/${organization?.key}`,
    isOrgMember: !!organization?._links?.self,
    isOrgSuperAdmin: !!organization?._links?.superAdministrate,
    isOrgLimitedAdmin: !!organization?._links?.administrate,
    isOrgModerator: !!organization?._links?.moderate,
    storageUrl,
    cms: `${storageUrl}/${activeOrgKey}/cms/`,
    keyArt: `${storageUrl}/${activeOrgKey}/key_art/`,
    getRoutedPath
  }
}
